import React from 'react';
import flyer from '../images/flyer.jpg';

export class Home extends React.Component {
    render() {
        return (
            <section className='page-layout'>
                <img src={flyer} className='flyer' alt='Porchfest Flyer' />
            </section>
        )
    }
}

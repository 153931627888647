import React from 'react';

const acts = [
    {
        time: '3-4pm',
        artist: 'Get Born',
        location: '817 E 5th Ave',
        description: 'Get Born is an indie folk surf duo led by Mason Feurer.',
    },
    {
        time: '3-4pm',
        artist: 'Scott Lippitt',
        location: '367 J Street',
        description: 'Scott Lippitt is a singer-songwriter with unique original songs to share. Scott utilizes a looper to make rich songs with just a voice and guitar.',
    },
    {
        time: '4-5pm',
        artist: 'AMEA',
        location: '803 E 4th Ave',
        description: 'AMEA is an r&b solo singer-songwriter who plays keyboard.',
    },
    {
        time: '4-5pm',
        artist: 'Kevyn Dern',
        location: '516 E 2nd Ave',
        description: 'Kevyn Dern is an Americana solo vocalist and guitarist. He plays original songs and covers of older roots, bluegrass, country and rock songs.',
    },
    {
        time: '4-5pm',
        artist: 'L. Alexander',
        location: '986 E 3rd Ave',
        description: 'L. Alexander is a solo singer-songwriter who plays bluesy indie-folk.',
    },
    {
        time: '5-6pm',
        artist: 'Brentlee Williams',
        location: '803 E 4th Ave',
        description: 'Brentlee Williams is a singer songwriter originally from Kansas City, Missouri. He draws influence from glen hansard, Jason isbell, Rustin Kelly, counting crows.',
    },
    {
        time: '5-6pm',
        artist: 'CBOB & the Bobinators',
        location: '516 E 2nd Ave',
        description: 'CBOB & the Bobinators is a local Dick Stusso cover band consisting of Christian Carrick on guitar and vocals, Aaron McMurray on bass and keys, and Darius Carrick on drums.',
    },
    {
        time: '6-7pm',
        artist: 'Brandon Aspittle',
        location: '1st & A',
        description: 'Brandon Aspittle is an acoustic guitarist and vocalist who will be performing a mix between originals, improv, and altered covers.',
    },
    {
        time: '6-7pm',
        artist: 'Eliza Mei',
        location: '583 E 5th Ave',
        description: 'Eliza Mei is a flutist and vocalist who plays jazz, folk and pop.',
    },
    {
        time: '7-8pm',
        artist: 'Kid Brother',
        location: '1st & A',
        description: 'Kid Brother is the solo project of Chase Gillins, a one-man-band who plays Americana and Folk.',
    },
    {
        time: '7-8pm',
        artist: 'Molly Mars',
        location: '132 E Street (Weixler Gallery)',
        description: 'Molly Mars is a local singer-songwriter who plays classic rock inspired folk-pop.',
    },
]

const tdStyle = {
    style: {
        padding: '6px 0',
    }
}

export class Schedule extends React.Component {
    render() {
        return (
            <section className='page-layout'>
                <h1>Schedule</h1>
                <table>
                    <thead style={{ textAlign: 'left' }}>
                        <th style={{ width: '6%' }}>
                            Time
                        </th>
                        <th style={{ width: '14%' }}>
                            Artist
                        </th>
                        <th style={{ width: '16%' }}>
                            Location
                        </th>
                        <th style={{ width: '40%' }}>
                            Description
                        </th>
                    </thead>
                    <tbody>
                        {acts.map(a => <tr style={{ padding: '12px' }}>
                            <td {...tdStyle}>{a.time}</td>
                            <td {...tdStyle}>{a.artist}</td>
                            <td {...tdStyle}>{a.location}</td>
                            <td style={{ fontSize: '14px', padding: '6px 0' }}>{a.description}</td>
                        </tr>)}
                    </tbody>
                </table>
            </section>
        )
    }
}

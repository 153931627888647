import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../images/logo.png';
import { ReactComponent as Instagram } from '../images/instagram.svg';
import email from '../images/email.svg';

export class Navigation extends React.Component {
    render() {
        return (
           <nav className='navigation'>
               <Link className='navigation-link' to='/'>
                   <img src={logo} className='logo' alt='Porchfest' />
               </Link>
               <Link className='navigation-link' to='/schedule'>Schedule</Link>
               <Link className='navigation-link' to='/sign-up'>Sign Up</Link>
               <div className='navigation-icon-wrapper'>
                <a href='https://www.instagram.com/porchfest_aves'>
                    <Instagram className='navigation-icon' title='Follow Porchfest on Instagram'/>
                </a>
                <a href='mailto:porchfestslc@gmail.com'>
                    <img src={email} className='navigation-icon email-icon' title='Email Porchfest' alt='Email Porchfest'/>
                </a>
               </div>
           </nav>
        )
    }
}

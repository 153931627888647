import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { Navigation } from './components/Navigation';
import { Home } from './components/Home';
import { Schedule } from './components/Schedule';
import { SignUp } from './components/SignUp';
import './App.css';

export default class App extends React.Component {
  render() {
    return (
      <BrowserRouter>
        <Navigation />
        <Switch>
          <Route path='/' exact>
            <Home />
          </Route>
          <Route path='/schedule'>
            <Schedule />
          </Route>
          <Route path='/sign-up'>
            <SignUp />
          </Route>
        </Switch>
      </BrowserRouter>
    )
  }
}

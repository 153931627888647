import React from 'react';

export class SignUp extends React.Component {
    render() {
        return (
            <section className='page-layout'>
                <a href='https://forms.gle/EA3GkjzFEVsiobUQ6' className='signup-link'>
                    Sign up to host from your porch
                </a>

                <a href='https://forms.gle/4XGuNtdCX1z79one8' className='signup-link'>
                    Sign up to perform on a porch
                </a>
            </section>
        )
    }
}
